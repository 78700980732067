import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <body>
      <header>
        <svg class="logo" width="50" height="50" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="48" fill="#4CAF50" stroke="#333" stroke-width="2" />
          <path d="M50 5 L60 30 L40 30 Z" fill="#FFEB3B" />
          <path d="M50 15 L50 70" stroke="#333" stroke-width="4" />
          <path d="M40 80 A10 10 0 0 1 60 80" fill="#FFEB3B" />
        </svg>
        <h1>Climate Change Progress</h1>
      </header>
      <nav>
        <a href="#international">International Policies</a>
        <a href="#technological">Technological Innovations</a>
        <a href="#corporate">Corporate Responsibility</a>
        <a href="#grassroots">Grassroots Movements</a>
      </nav>
      <div class="container">
        <div class="new-card" id="international">
          <img class="new-image" src="goverment.jpg" alt="Solar Panels" />
          <h2>International Agreements and National Policies</h2>
          <p>Internationally, the Paris Agreement remains the cornerstone of global climate efforts. Since its inception in 2015, nations have periodically revised their commitments to reduce greenhouse gas emissions. The most recent Conference of the Parties (COP28) emphasized the urgency of achieving net-zero emissions by mid-century, with many countries enhancing their Nationally Determined Contributions (NDCs). The United States rejoining the Paris Agreement under President Biden has reinvigorated global momentum, bringing a renewed focus on collaborative climate action.</p>
          <p>The European Union continues to lead with its ambitious Green Deal, aiming for carbon neutrality by 2050. The deal includes measures like the Carbon Border Adjustment Mechanism (CBAM), which aims to prevent "carbon leakage" by imposing tariffs on carbon-intensive imports. Similarly, China, the world’s largest emitter, has pledged to peak its carbon emissions before 2030 and achieve carbon neutrality by 2060, setting a precedent for other developing nations.</p>
        </div>
        <div class="new-card" id="technological">
          <img class="new-image" src="ev.jpg" alt="Wind Turbines" />
          <h2>Technological Innovations</h2>
          <p>Technological advancements are playing a critical role in addressing climate change. Renewable energy technologies, particularly solar and wind, have seen remarkable cost reductions, making them more competitive with fossil fuels. Battery storage technology is also advancing, addressing the intermittent nature of renewables and enhancing grid stability.</p>
          <p>Electric vehicles (EVs) are another area of significant progress. Major automakers are accelerating their transition to electric, with companies like General Motors pledging to phase out gasoline and diesel vehicles by 2035. Advances in EV battery technology are increasing range and reducing costs, making electric vehicles more accessible to the general public.</p>
          <p>Carbon capture and storage (CCS) technologies are also developing, offering potential solutions for industries that are hard to decarbonize. Enhanced methods of direct air capture (DAC) are being piloted, with companies like Climeworks leading the way in capturing CO2 directly from the atmosphere and storing it underground or repurposing it.</p>
        </div>
        <div class="new-card" id="corporate">
          <img class="new-image" src="solar.jpg" alt="Corporate Responsibility" />
          <h2>Corporate Responsibility and Investment</h2>
          <p>Corporate responsibility has seen a paradigm shift as businesses recognize the importance of sustainable practices. Many corporations are setting science-based targets for emissions reductions and investing in renewable energy. The financial sector is increasingly factoring climate risk into investment decisions, with a surge in green bonds and sustainable finance instruments.</p>
          <p>Investment in clean energy and sustainable technologies is growing. According to the International Energy Agency (IEA), global energy investment is set to rise by 8% in 2024, with a significant portion directed towards renewables. Venture capital is flowing into climate tech startups, fostering innovation and accelerating the deployment of green technologies.</p>
        </div>
        <div class="new-card" id="grassroots">
          <img class="new-image" src="protest.jpg" alt="Protestors" />
          <h2>Grassroots Movements and Public Awareness</h2>
          <p>Public awareness and activism are critical drivers of climate action. Movements like Fridays for Future, led by youth activist Greta Thunberg, have galvanized millions around the world, demanding more ambitious climate policies. These movements have put pressure on governments and corporations to act more decisively.</p>
          <p>Educational initiatives and increased media coverage are also raising awareness about climate change and its impacts. This heightened awareness is translating into consumer behavior changes, with more individuals opting for sustainable products and lifestyles.</p>
        </div>
        <h2>Conclusion</h2>
        <p>While significant challenges remain, the progress in tackling climate change is evident across various domains. International cooperation, technological innovation, corporate responsibility, and grassroots activism are all contributing to a multi-faceted approach to mitigate the impacts of climate change. Continued efforts and increased ambition will be crucial in ensuring a sustainable and resilient future for generations to come.</p>

        <div class="links">
          <a href="#international">International Policies</a>
          <a href="#technological">Technological Innovations</a>
          <a href="#corporate">Corporate Responsibility</a>
          <a href="#grassroots">Grassroots Movements</a>
        </div>

        <div class="shape"></div>
      </div>
    </body>

  );
}

export default App;
